/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components

import { useState,useEffect} from "react";
// @mui material components
import Card from "@mui/material/Card";
import fmna from "assets/images/calls/f-m-NA.png";
import fma from "assets/images/calls/f-m-A.png";
import fmo from "assets/images/calls/f-m-o.png";

import mfna from "assets/images/calls/m-f-NO.png";
import mfa from "assets/images/calls/m-f-A.png";
import mfo from "assets/images/calls/m-f-o.png";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Icon from "@mui/material/Icon";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import axios from "axios";
import TimeAgo from 'react-timeago'
import frenchStrings from 'react-timeago/lib/language-strings/fr'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import { Clicktocall } from "examples/utiles/clicktocall";

function ProfilesList({ title,poste,collegue, nbr_appel_afficher,shadow }) {
  const formatter = buildFormatter(frenchStrings)
  const [logdata, setDatalog] = useState([]);
  const [loading, setLoading] = useState(true);
  const getcalllogwithclient =(poste,collegue, nbr_appel_afficher) => {
    
    const authToken=localStorage.getItem("accestoken");
    const config = {
        headers: {
          Authorization: authToken,
        },
      };
    axios.get('https://41.226.14.6:4444/comunik_ipbx/Api/WS/LogAppelAvecColleguesIPBXV3API?compte=client&poste='+poste+'&collegue='+collegue+'&nbr_appel_afficher='+nbr_appel_afficher,config)
            .then((response) => {
              if (response.status === 200) {
                const res = response.data;
                console.log(res);
                setDatalog(res.log_appel);
                setLoading(false);
               
              }
            })
            .catch((error) => {
              console.error(error);
            });


  }
  const getavatar=(sens,dispo)=>{
    var avartar="";
    if(sens=="1"){
      switch (dispo) {
        case "NO ANSWER":
          avartar=mfna;
          break;
        case "BUSY":
          avartar=mfo;
        break;

        case "ANSWERED":
          avartar=mfa;
        break;
      
        default:
          break;
      }

    }else{

      switch (dispo) {
        case "NO ANSWER":
          avartar=fmna;
          break;
        case "BUSY":
          avartar=fmo;
        break;

        case "ANSWERED":
          avartar=fma;
        break;
      
        default:
          break;
      }
    }
    return avartar;

  }
  useEffect(() => {
    const interval = setInterval(() => {
      getcalllogwithclient(poste,collegue, nbr_appel_afficher);
    }, 3000)
    return () => clearInterval(interval)
    
  }, [collegue]);
  

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {loading?(
            <Stack spacing={1}>
              <Skeleton variant="rounded" width={"100%"} height={60} />
              <Skeleton variant="rounded" width={"100%"} height={60} />
              <Skeleton variant="rounded" width={"100%"} height={60} />
              <Skeleton variant="rounded" width={"100%"} height={60} />
              <Skeleton variant="rounded" width={"100%"} height={60} />
              </Stack>
          ):(logdata.map((item) => (
    <MDBox key={item.id} component="li" display="flex" alignItems="center" py={1} mb={1}>
      <MDBox mr={2}>
        <MDAvatar src={getavatar(item.sens_appel,item.disposition)} alt="something here" shadow="md" />
      </MDBox>
      <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
        <MDTypography variant="button" fontWeight="medium">
          {item.sens_appel=="0"?item.dst:item.src}
        </MDTypography>
        <MDTypography variant="caption" color="text">
        <TimeAgo date={item.calldate_start} formatter={formatter} />
          
        </MDTypography>
      </MDBox>
      <MDBox ml="auto">
      <MDButton  color="success"  onClick={() => Clicktocall(item.sens_appel=="0"?item.dst:item.src)} ><Icon fontSize="small">call</Icon></MDButton>
      </MDBox>
    </MDBox>
  )))}
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfilesList
ProfilesList.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList


export default ProfilesList;
