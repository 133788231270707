import { useMemo, useEffect } from "react";
import CryptoJS from "crypto-js";
import Iframe from "react-iframe";
import axios from "axios";
import { useNavigate } from "react-router-dom";
function Webphone(params) {
  const navigate = useNavigate();
  const iframeContent = useMemo(
    () => {
      console.log(params);
      let url;
      if (localStorage.getItem("accestoken") === null) {
        axios
          .get(
            "https://41.226.14.6:4444/comunik_ipbx/Api/WS/ConnexionIPBXV3API?compte=client&login=" +
            params.webposte +
              "&passwd=" +
              params.webpassword
          )
          .then((response) => {
            if (response.status === 200) {
              const res = response.data;
              localStorage.setItem("accestoken", res.AccessToken);
              const i = `name=${params.poste}&pwd=${params.password}&ip=41.226.14.6&port=8089&accestoken=${res.AccessToken}&compte=Client`;
              const d = CryptoJS.AES.encrypt(JSON.stringify(i), "my-secret-key@123").toString();
              url = `https://41.226.14.6:4444/webphone_V3/webphone_v3?${d}`;
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        const i = `name=${params.poste}&pwd=${params.password}&ip=41.226.14.6&port=8089&accestoken=${localStorage.getItem(
          "accestoken"
        )}&compte=Client`;
        const d = CryptoJS.AES.encrypt(JSON.stringify(i), "my-secret-key@123").toString();
        url = `https://41.226.14.6:4444/webphone_V3/webphone_v3?${d}`;
      }
      return (
        <Iframe
          url={url}
          width="250px"
          height="350px"
          id="webphoneframe"
          className=""
          allow="camera;microphone;autoplay;notification"
          display="block"
          position="relative"
        />
      );
    },
    [
      /* Dependencies that affect the iframe content */
    ]
  );
  const myFunction = (callerNumber) => {
    // Your function logic here, which can use the "data" parameter
    if (callerNumber.length >= 8) {
      axios
        .get("https://emailing.tus-appli.com/sav/getclientbyphone/" + callerNumber)
        .then((response) => {
          if (response.status === 200) {
            const res = response.data;
            navigate("/profile/" + res[0]);
            console.log(res);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleMessage = (event) => {
    if (event.data.action === "callMyFunction") {
      myFunction(event.data.data);
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return <>{iframeContent}</>;
}
export default Webphone;
