import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import axios from "axios";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import { useNavigate , useParams} from 'react-router-dom';

function MyEditorEdit() {
  const { Id } = useParams();
  const [value, setValue] = useState("");
  const [Model, setModel] = useState("");
  const [Title, setTitle] = useState("");
  const [successSB, setSuccessSB] = useState(false);
  const [warningSB, setWarningSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const navigate = useNavigate();
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openWarningSB = () => setWarningSB(true);
  const closeWarningSB = () => setWarningSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote", "code-block"],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction

      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      ["link", "image", "video"],

      ["clean"],
    ],
  };
  const handlesave = async () => {
    const username = JSON.parse(localStorage.getItem("authuser")).username;

    if (Model == "" || Title == "" || value == "") {
      openWarningSB();
    } else {
      let data = new FormData();
      data.append("model", Model);
      data.append("Titre", Title);
      data.append("script", value);
      data.append("editor", username);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://emailing.tus-appli.com/scripts/edit/"+Id,
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          if (response.status === 200) {
            openSuccessSB();
            navigate('/scripts');
           
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

 
  const getscriptbyid= async (inputValue)=>{
    try {
        const response = await axios.get(
          `https://emailing.tus-appli.com/scripts/view/${inputValue}`
        );
        const res = response.data;
        if(res.status=="success"){
            setValue(res.data.script);
            setTitle(res.data.Titre);
            setModel(res.data.model);
        } // Assuming your API returns an array of options
       
      } catch (error) {
        console.error("Error fetching data:", error);
      }
  }
  useEffect(() => {
    getscriptbyid(Id);
  }, [Id]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card pb={10}>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container spacing={6}>
                  <Grid item xs={6} display="flex" justifyContent="start">
                    <MDTypography variant="h6" color="white">
                      Modifier le script de {Model}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} display="flex" justifyContent="end"></Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <Grid container spacing={6}>
                  <Grid item xs={12} display="flex" justifyContent="center">
                    <MDInput
                      type="text"
                      label="Titre de script"
                      value={Title}
                      onChange={e => setTitle(e.target.value)}
                      style={{ width: "400px" }}
                    />
                  </Grid>
                  

                  <Grid item xs={12} display="flex" justifyContent="center">
                    <MDBox>
                      <ReactQuill
                        theme="snow"
                        value={value}
                        onChange={setValue}
                        modules={modules}
                        style={{ height: "400px" }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} display="flex" justifyContent="end" p={6}>
                    <MDButton color="success" onClick={handlesave}>
                      Enregistrez
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDSnackbar
      color="error"
      icon="warning"
      title="Material Dashboard"
      content="Hello, world! This is a notification message"
      dateTime="11 mins ago"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
     <MDSnackbar
      color="success"
      icon="check"
      title="Material Dashboard"
      content="Hello, world! This is a notification message"
      dateTime="11 mins ago"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
      <MDSnackbar
      color="warning"
      icon="description"
      title="empty field"
      content=""
      dateTime="11 seconds ago"
      open={warningSB}
      onClose={closeWarningSB}
      close={closeWarningSB}
      bgWhite
    />
      <Footer />
    </DashboardLayout>
  );
}

export default MyEditorEdit;
