/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import DirectionsIcon from '@mui/icons-material/Directions';
import Icon from "@mui/material/Icon";
import Alert from '@mui/material/Alert';
import Bill from "layouts/billing/components/Bill";
import MDButton from "components/MDButton";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import axios from "axios";
function Findserial() {
  const [RSresult, setRSresult] = useState([]);
  const [SerialNumber, setSerialNumber] = useState("");
  const [RSstatus, setRSstatus] = useState("");
  const [alerttext, setAlerttext] = useState("Chercher votre Numéro de serie");
  const [loading, setLoading] = useState(false);
  const getexist = async SN => {
    if (SN != null && SN != "") {
      setSerialNumber(SN);
      setLoading(true);
     
        axios.get('https://emailing.tus-appli.com/sav/snexist/'+SN)
        .then((response) => {
          if (response.status === 200) {
            const res = response.data;
        setRSresult(res.data);
        getmodalscripts(res.data.b)
        setRSstatus(res.status);
        setLoading(false);
        console.log(res);
           
           
          }
        })
        .catch((error) => {
          console.error(error);
        });

       
    } else {
      setAlerttext("vous devez remplir le champ du numéro de série !");
    }
  };
  const getmodalscripts= (model)=>{ 
    axios.get('https://emailing.tus-appli.com/scripts/view/model/'+model)
    .then((response) => {
      if (response.status === 200) {
        const res = response.data;
   
    console.log(res);
       
       
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
  const handleDetailClick = async () => {
    getexist(SerialNumber);
  };

  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox p={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Chercher le SN
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
        
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
        <Paper
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%'}}
    >
      <IconButton sx={{ p: '10px' }} aria-label="menu">
        <MenuIcon />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Please input SN"
        id="serialn"
        onChange={e => setSerialNumber(e.target.value)}
        value={SerialNumber}
      />
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleDetailClick}>
        <SearchIcon />
      </IconButton>
      
     
    </Paper>
 
        </MDBox>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
        <Grid item xs={12}>
        <Card id="delete-account">
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          Resultat
        </MDTypography>
        
      </MDBox>
      {loading ? ( <CircularProgress />) : (RSstatus == "success"?(
        <MDBox p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <MDBox
              borderRadius="lg"
              display="flex"
            
              p={3}
              sx={{
                border: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              }}
            >
              <Bill
              SN={RSresult.a}
              Model={RSresult.b}
              Designation={RSresult.c}
              Date={RSresult.d}
              Client={RSresult.e}
              garentie={RSresult.g}
              />
    
            </MDBox>
          
          </Grid>
          
        </Grid>
      </MDBox>
      ):(
        RSstatus == "empty"?(<Alert severity="error">Ce produit n'est pas original TUS</Alert>):(<></>)
        
      ))}

      
    
    </Card>
        </Grid>
        </MDBox>
        
        
        
        
        
      </MDBox>
    </Card>
  );
}

export default Findserial;
