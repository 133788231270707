/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import { useState, useEffect } from "react";
// Overview page components
import Header from "layouts/profile/components/Header";
import Findserial from "layouts/profile/components/Findserial";
import axios from "axios";
// Data
import profilesListData from "layouts/profile/data/profilesListData";
import { useParams } from "react-router-dom";
// Images
import MDAvatar from "components/MDAvatar";

// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
// Images
import burceMars from "assets/images/user-2.png";
import backgroundImage from "assets/images/bg-profile.jpeg";
function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && (
        <MDBox mt={5} mb={3}>
          {children}
        </MDBox>
      )}
    </div>
  );
}
function Overview() {
  const [userinfo, setUser] = useState([]);
  const [userlogs, setUserlogs] = useState("");
  const { userId } = useParams();
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const globaluser = JSON.parse(localStorage.getItem("authuser"));
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const getclientinfo = (user) => {
    axios
      .get(`https://emailing.tus-appli.com/sav/getclientbycode/` + user)
      .then((response) => {
        if (response.status === 200) {
          const res = response.data;
          console.log(res);
          setUser(res);
          rendercalllog(res);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const rendercalllog = (input) => {
    setUserlogs(
      <ProfilesList
        title={"Journal des appels avec " + input.Name}
        poste={globaluser.ext_num}
        collegue={input.Phone_No}
        nbr_appel_afficher="5"
        shadow={false}
      />
    );
  };
  useEffect(() => {
    getclientinfo(userId);
  }, [userId]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="5rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.6),
                rgba(gradients.info.state, 0.6)
              )}, url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />
        <Card
          sx={{
            position: "relative",
            mt: -8,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <MDAvatar src={burceMars} alt="profile-image" size="xl" shadow="sm" />
            </Grid>
            <Grid item>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  {userinfo.Name}
                </MDTypography>
                <MDTypography variant="button" color="text" fontWeight="regular">
                  {userinfo.VAT_Registration_No}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
              <AppBar position="static">
                <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                  <Tab
                    label="Info"
                    value={0}
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        home
                      </Icon>
                    }
                  />
                  <Tab
                    label="Numéro de série"
                    value={1}
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        qrcode
                      </Icon>
                    }
                  />
                  <Tab
                    label="Journal d'appels"
                    value={2}
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        phonelink
                      </Icon>
                    }
                  />
                </Tabs>
              </AppBar>
            </Grid>
          </Grid>
          
          <TabPanel value={tabValue} index={0}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={4} xl={4} sx={{ display: "flex" }}>
                <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
                <ProfileInfoCard
                  style={{ width: "100%" }}
                  title={userId}
                  description=""
                  info={{
                    fullName: userinfo.Name,
                    mobile: userinfo.Phone_No,
                    Commercial: userinfo.Responsibility_Center,
                    location: userinfo.Country_Region_Code,
                  }}
                  social={[]}
                  action={{ route: "", tooltip: "" }}
                  shadow={false}
                />
                <Divider orientation="vertical" sx={{ mx: 0 }} />
              </Grid>
               <Grid item xs={12} xl={4}>
               
               </Grid>
              <Grid item xs={12} xl={4}>
                {userlogs}
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
          <Findserial />
          </TabPanel>
        
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
