/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useState, useRef } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import HTMLRenderer from "react-html-renderer";
// Data
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import TimeAgo from "react-timeago";
import frenchStrings from "react-timeago/lib/language-strings/fr";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import Stack from "@mui/material/Stack";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "./style.css";
function Scripts() {
  const formatter = buildFormatter(frenchStrings);
  const navigate = useNavigate();
  const [loadingcontent,setLoading]=useState(false);
  const handleAdd = () => navigate("/scripts/add");
  const [openmodal, setOpenmodal]=useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [Script, setScript] = useState([]);
  const [deletedID, setdeletedID] = useState("");
  const handleClickOpen = (id) => {
    setLoading(true);
    setOpenmodal(true);
    axios
    .get("https://emailing.tus-appli.com/scripts/view/" + id)
    .then((response) => {
      if (response.status === 200) {
        if (response.data.status == "success") {
          setScript(response.data.data);
         
          setLoading(false);
        }
      }
    })
    .catch((error) => {
      console.error(error);
    });
   
    
  };

  const handleClose = () => {
    setOpenDelete(false);
   
  };

  const [rows, setRows] = useState([]);
  const columns = [
    { Header: "Model", accessor: "model", align: "left" },
    { Header: "Titre", accessor: "Titre", width: "45%", align: "left" },
    { Header: "Auteur", accessor: "editor", align: "center" },
    { Header: "DATE ET HEURE", accessor: "date_script", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];
  const deletefunction = (id) => {
    axios
      .get("https://emailing.tus-appli.com/scripts/delete/" + id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status == "success") {
            handleClose();
            fetchscripts();
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const fetchscripts = () => {
    axios
      .get("https://emailing.tus-appli.com/scripts")
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          addtoarray(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleDelete = (id) => {
    setdeletedID(id);
    setOpenDelete(true);
  };
  const addtoarray = (data) => {
    const newArray = [];
    data.map((item) => {
      newArray.push({
        model: item.model,
        Titre: item.Titre,
        editor: item.editor,
        date_script:item.date_script ,
        action: (
          <Stack direction="row" spacing={1}>
            <MDButton
              variant="outlined"
              color="success"
              onClick={() => handleClickOpen(item.id)}
              iconOnly
            >
              <Icon fontSize="small">visibility</Icon>
            </MDButton>
            <MDButton
              variant="outlined"
              color="warning"
              onClick={() => navigate("/scripts/edit/" + item.id)}
              iconOnly
            >
              <Icon fontSize="small">mode</Icon>
            </MDButton>
            <MDButton
              variant="outlined"
              color="error"
              onClick={() => handleDelete(item.id)}
              iconOnly
            >
              <Icon fontSize="small">close</Icon>
            </MDButton>
          </Stack>
        ),
      });
    });
    setRows(newArray);
  };
 
  const closemodal=()=>{
    setOpenmodal(false);
  }
  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (openmodal) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openmodal]);
  useEffect(() => {
    fetchscripts();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container spacing={6}>
                  <Grid item xs={6} display="flex" justifyContent="start">
                    <MDTypography variant="h6" color="white">
                      Liste des Scripts
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} display="flex" justifyContent="end">
                    <MDButton color="success" onClick={handleAdd}>
                      {" "}
                      Ajouter scripts
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  canSearch={true}
                  showTotalEntries={true}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
     
      <Dialog
        open={openDelete}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Etes-vous sûr d'avoir supprimé ce script ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton
            color="error"
            onClick={() => {
              deletefunction(deletedID);
            }}
          >
            Supprimer
          </MDButton>
          <Button onClick={handleClose}>Fermer</Button>
        </DialogActions>
      </Dialog>
      <Dialog
    fullWidth={true}
    maxWidth={"xl"}
    
    open={openmodal}
    
    scroll="paper"
    aria-labelledby="scroll-dialog-title"
    aria-describedby="scroll-dialog-description"
  >
    <DialogTitle id="scroll-dialog-title">{loadingcontent?(<Skeleton  width={400}/>):(Script.model)}</DialogTitle>
    <DialogContent dividers={true}>
      <DialogContentText
        id="scroll-dialog-description"
        ref={descriptionElementRef}
        tabIndex={-1}
      >
        <MDBox display="flex" justifyContent="center">
          <MDTypography variant="h5">{loadingcontent?(<Skeleton width={210}/>):(Script.Titre)}</MDTypography>
        </MDBox>

        <MDBox pt={3}>
          {loadingcontent?(
              <MDBox display="flex" justifyContent="center">
              <CircularProgress />
            </MDBox>
          ):(
            <HTMLRenderer
            id="HTMLRenderercontent"
            html={Script.script}
            style={{
              img: {
                width: '500px !important', // Set your desired width here
                height: '100%', // Set your desired height here
              },
            }}
          />
          )}
          
        </MDBox>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={closemodal}>Fermer</Button>
    </DialogActions>
  </Dialog>
      <Footer />
    </DashboardLayout>
  );
}

export default Scripts;
