/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useState } from 'react';
import Card from "@mui/material/Card";
import axios from 'axios';
import Calendar from '@toast-ui/react-calendar';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';

function Calendars() {
  const [events, setEvents] = useState([]);
  const MOCK_CALENDARS = [{
    id: '1',
    name: 'Formation',
    color: '#ffffff',
    borderColor: '#9e5fff',
    backgroundColor: '#9e5fff',
    dragBackgroundColor: '#9e5fff',
},
{
    id: '2',
    name: 'Intervention',
    color: '#ffffff',
    borderColor: '#03bd9e',
    backgroundColor: '#03bd9e',
    dragBackgroundColor: '#03bd9e',
},{
    id: '3',
    name: 'Formation en ligne',
    color: '#ffffff',
    borderColor: '#fc4747',
    backgroundColor: '#fc4747',
    dragBackgroundColor: '#fc4747',
},

];


async function fetchData() {
  try {
    const response = await axios.get('https://emailing.tus-appli.com/sav/getallformation'); // Replace with your API URL
    return response.data; // Return the data from the response
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error; // Rethrow the error to handle it elsewhere
  }
}
  
useEffect(() => {
  fetchData()
    .then((fetchedData) => {
      setEvents(fetchedData);
    })
    .catch((error) => {
      // Handle the error here
    });
}, []);

  return (
    <Card>
      <Calendar 
          view="month"
           useCreationPopup={true}
          useDetailPopup={true}
          calendars={MOCK_CALENDARS}
          events={events}/>  
    </Card>
  );
}

export default Calendars;
