import axios from "axios";
export function Clicktocall(value) {
    // Your global function logic here
    console.log('Global function called.');
    const authToken=localStorage.getItem("accestoken");
    const user=JSON.parse(localStorage.getItem("authuser"));
    const config = {
        headers: {
          Authorization: authToken,
        },
      };
    // Your logic here
    axios
    .get('https://41.226.14.6:4444/comunik_ipbx/Api/WS/ClickToCallPBXV3API?compte=client&poste='+user.ext_num+'&telephone='+value, config) // Replace with your API URL
    .then((response) => {
      
     // console.log(response);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      // Handle the error here
      
    });
  }