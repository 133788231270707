/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useState } from 'react';
import axios from 'axios';
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from 'components/MDButton';
import Icon from "@mui/material/Icon";
import { Clicktocall } from "examples/utiles/clicktocall";
// Images
import fmna from "assets/images/calls/f-m-NA.png";
import fma from "assets/images/calls/f-m-A.png";
import fmo from "assets/images/calls/f-m-o.png";
import failed from "assets/images/calls/failed.png"
import mfna from "assets/images/calls/m-f-NO.png";
import mfa from "assets/images/calls/m-f-A.png";
import mfo from "assets/images/calls/m-f-o.png";

export default function Data() {
  const [calllogdata, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const getcolllog=()=>{
    const authToken=localStorage.getItem("accestoken");
    const user=JSON.parse(localStorage.getItem("authuser"));
    const config = {
        headers: {
          Authorization: authToken,
        },
      };
    axios
    .get('https://41.226.14.6:4444/comunik_ipbx/Api/WS/LogAppelIPBXV3API?compte=client&poste='+user.ext_num+'&filter=all', config) // Replace with your API URL
    .then((response) => {
      addtoarray(response.data.log_appel);
      setLoading(false);
      
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      // Handle the error here
      setLoading(true);
    });
}
const getavatar=(sens,dispo)=>{
  var avartar="";
  if(sens=="1"){
    switch (dispo) {
      case "NO ANSWER":
        avartar=mfna;
        break;
      case "BUSY":
        avartar=mfo;
      break;

      case "ANSWERED":
        avartar=mfa;
      break;
    
      default:
        avartar=failed;
        break;
    }

  }else{

    switch (dispo) {
      case "NO ANSWER":
        avartar=fmna;
        break;
      case "BUSY":
        avartar=fmo;
      break;

      case "ANSWERED":
        avartar=fma;
      break;
    
      default:
        avartar=failed;
        break;
    }
  }
  return avartar;

}

const addtoarray=(data)=>{
  const newArray = [];
  data.map((item) => {
    const match = item.clid.match(/^(.*?) <(\d+)>$/);
    newArray.push({
      author: <Author image={getavatar(item.sens_appel,item.disposition)} name={match[1]} email={item.sens_appel=="0"?item.dst:item.src} />,
      function: <Job title={item.calldate_start} description="" />,
      status: (
        <MDBox ml={-1}>
          <MDBadge badgeContent="online" color="success" variant="gradient" size="sm" />
        </MDBox>
      ),
      employed: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {item.billsec}
        </MDTypography>
      ),
      action: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          <MDButton  color="success"  onClick={() => Clicktocall(match[2])} ><Icon fontSize="small">call</Icon></MDButton>
        </MDTypography>
      ),
    });

  })
  setData(newArray.reverse())

}

useEffect(() => {
   getcolllog();

}, []);
  
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  return {
    columns: [
      { Header: "NOM", accessor: "author", width: "45%", align: "left" },
      { Header: "DATE ET HEURE", accessor: "function", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "DATE ET HEURE", accessor: "employed", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ], 

    rows:calllogdata

  };
}
