/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useState } from 'react';
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
import axios from "axios";


function Collegue() {
    const [colleguedata, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    
    const getcollege=()=>{
    const authToken=localStorage.getItem("accestoken");
    const user=JSON.parse(localStorage.getItem("authuser"));
    const config = {
        headers: {
          Authorization: authToken,
        },
      };
    axios
    .get('https://41.226.14.6:4444/comunik_ipbx/Api/WS/ColleguesIPBXV3APIPROFIL?compte=client&poste='+user.ext_num, config) // Replace with your API URL
    .then((response) => {
      setData(response.data.collegues);
      setLoading(false);
      console.log(colleguedata);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      // Handle the error here
      setLoading(true);
    });
}

useEffect(() => {
    getcollege();

}, []);
  return (
    <DashboardLayout>
    <DashboardNavbar />
    <MDBox mt={2}>
      <MDBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            

            {loading ? (
        <p>Loading...</p>
      ) : (
        <Grid container spacing={3}>
        {colleguedata.map((item) => (
            <Grid item xs={12} md={6} xl={3} key={item.id} >
            <DefaultInfoCard
              icon="person"
              title={item.nom}
              description={item.poste}
              value={item.poste}
              etat={item.etat}
             
            />
          </Grid>
           ))}
        </Grid>
      )}
           
             
             
             
            
          </Grid>
         
        </Grid>
      </MDBox>
      
    </MDBox>
    <Footer />
  </DashboardLayout>
  );
}

export default Collegue;
